@tailwind base;
@tailwind components;


/* @import 'highlight'; */
@import 'dracula';
@import 'base';
@import 'navigation';
@import 'search';
@import 'clipboard';

@tailwind utilities;
