
/*
.docsearch-input {
    background-image: url('assets/img/magnifying-glass.svg');
    background-position: 0.8em;
    background-repeat: no-repeat;
    text-indent: 1.2em;

    @screen lg {
        &:focus {
            @apply w-2/3;
        }
    }

    @screen xl {
        &:focus {
            @apply w-3/4;
        }
    }

     &__wrapper {
        @apply absolute top-0 left-0 z-10 w-full px-4 mt-8 bg-white;

        @screen md {
            @apply relative px-0 mt-0;
        }
    }
}*/

.algolia-autocomplete {


    @apply w-full text-right;

    input#docsearch:focus {
        @apply border-2 border-indigo-500 outline-none;
    }

    .ds-dropdown-menu {
        @apply w-full;

        max-width: 750px !important;
        min-width: auto !important;

        .algolia-docsearch-suggestion {
            .algolia-docsearch-suggestion--content {
                @apply w-full;

                @screen md {
                    @apply w-2/3;
                }
            }

            .algolia-docsearch-suggestion--text {
                @apply font-normal;
                line-height: 1.4;
            }

            .algolia-docsearch-suggestion--wrapper {
                @apply py-3;
            }

            .algolia-docsearch-suggestion--subcategory-column {
                @apply hidden;

                @screen md {
                    @apply inline-block w-1/3;
                }
            }
        }
    }
}


.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
    @apply text-indigo-700;
}